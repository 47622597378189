// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css'; // Import the CSS here
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import { createRoot } from 'react-dom/client'; // Correct import for React 18
import './index.css'; // Import the CSS here
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root'); // Ensure this matches the ID in your public/index.html
const root = createRoot(container); // Use createRoot from react-dom/client

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Measuring performance
reportWebVitals();
