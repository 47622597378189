import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './index.css'; // Assuming your CSS file is called index.css and located in the src folder
import { Facebook, Instagram, Linkedin } from "lucide-react";



const LandingPage = () => {
  const [language, setLanguage] = useState('th'); // Default language is Thai
  const [videoDuration, setVideoDuration] = useState(43000); // Default fallback duration

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === 'th' ? 'en' : 'th'));
  };
  const translations = {
    th: {
      navigation: {
        home: "หน้าแรก",
        useCases: "การใช้งาน",
        blog: "บล็อก",
        data: "ข้อมูล",
      },
      heroTitle: "ลดเวลางานเอกสาร เพิ่มคุณภาพทีมกฎหมาย<br />ด้วย KHORN AI",
      heroQuote: "“ใช้ AI ทำงานซ้ำซาก เพราะเวลาของทีมกฎหมายมีค่ามากกว่าการหาข้อมูล”",
      waitlistButton: "เข้าร่วม Waitlist",
     
      platformTitle: "แพลตฟอร์มของเรา",
      platformQuote: "KHORN คือแพลตฟอร์มที่ขับเคลื่อนด้วย AI สำหรับสำนักงานกฎหมายและผู้เชี่ยวชาญด้านกฎหมาย ช่วยลดงานซ้ำซากและเชื่อมต่อกับแหล่งข้อมูลสาธารณะและข้อมูลส่วนตัวได้ในระบบเดียวอย่างมีประสิทธิภาพ",
      
      legalWorkspace: "พื้นที่ทำงานวิจัยทางกฎหมาย<br />รูปแบบใหม่ของคุณ",
      aggregateInfoTitle: "รวบรวมข้อมูลอย่างครบถ้วน",
      aggregateInfo: "รับข้อมูลเชิงลึกจากเอกสารหลายร้อยฉบับในไม่กี่นาที",
      sourceReachTitle: "แหล่งข้อมูลอยู่ใกล้แค่เอื้อม",
      sourceReach: "ทุกการวิเคราะห์และคำตอบได้รับการสนับสนุนจากแหล่งข้อมูลขนาดใหญ่",
      endlessAccessTitle: "เข้าถึงได้อย่างไร้ขีดจำกัด",
      endlessAccess: "เข้าถึงกฎหมายสาธารณะและคดีที่บันทึกไว้ทั้งหมดในประเทศไทย",
      benefitsTitle: "ประโยชน์",
      benefitsQuote: "เพิ่มประสิทธิภาพการทำงานด้านกฎหมาย",
      unlockedKnowledgeTitle: "ความรู้ที่ถูกปลดล็อก",
      unlockedKnowledge: "ปลดล็อกความรู้จากงานเดิม นโยบาย และแม่แบบขององค์กร",
      improvedOutcomesTitle:"ผลลัพธ์ที่ดียิ่งขึ้น",
      improvedOutcomes: "ตรวจสอบอย่างลึกซึ้งในงานวิจัยและพิจารณาปัจจัยเพิ่มเติม",
      increasedProductivityTitle:"เพิ่มประสิทธิภาพการทำงาน",
      increasedProductivity: "ลดงานที่น่าเบื่อและใช้เวลาในการสร้างคุณค่าให้ลูกค้า",
      
      header: "ปัญหาพวกนี้กำลังทำให้<br />สำนักงานกฎหมายของคุณช้าลงหรือไม่?",
      problemsTitle: "นี่คือปัญหาของคุณรึเปล่า",
      problem1Title: "วุ่นวายกับการจัดการเอกสารกฎหมาย<br />ของลูกค้ามากมาย?",
      problem2Title: "เสียเวลางานไปกับการร่างเอกสารกฎหมายซ้ำๆ?",
      problem3Title: "ค้นหาคดีฎีกาและมาตรากฎหมายที่เกี่ยวข้องอย่างยากลำบาก?",

      handleTitle: "สำนักงานกฎหมายทำงานแบบนี้",
      handle1: "ติดตามคดีด้วยมือเสี่ยงต่อความไม่<br />เป็นระเบียบ",
      handle2: "ร่างเอกสารต่างๆในแต่ละคดี ใหม่<br />ทุกครั้งทำให้เสียเวลา",
      handle3: "ค้นหามาตราและฎีกาจากหลายกรมกระทรวงใช้เวลานาน",

      solutionTitle: "KHORN AI ช่วย",
      solution1Title: "รวมข้อมูลลูกค้าและคดีอย่างปลอดภัยพร้อมใช้งานไว้ในที่เดียว",
      solution2Title: "มี AI ช่วยร่างเอกสาร คำร้อง คำฟ้อง <br />ลดงานซ้ำซ้อน",
      solution3Title: "ช่วยค้นหามาตรากฎหมายและฎีกาที่เกี่ยวข้องได้ทันทีด้วย AI",

      closingStatement: "KHORN AI ช่วยให้กระบวนการทางกฎหมายง่ายขึ้น จัดการคดี ร่างเอกสาร และค้นหากฎหมายได้เร็วและมีประสิทธิภาพยิ่งขึ้น",
      featureSectionTitle: "ลองใช้ KHORN AI",
      featureSectionDesc: "ค้นพบว่า KHORN AI ช่วยให้กระบวนการทางกฎหมายของคุณง่ายขึ้นได้อย่างไร",
      featureTitle: "KHORN AI ฟีเจอร์",
      feature3Title: "AI ช่วยจัดการคดีเป็นระเบียบไปใช้ในอนาคต",
      feature3Desc: "จัดเก็บคดีลูกค้า เอกสารทางกฎหมาย และบันทึกทั้งหมดในระบบ<br />  เพิ่มประสิทธิภาพและและการจัดเก็บที่ปลอดภัย",
      feature2Title: "AI ช่วยร่างเอกสารทางกฎหมาย",
      feature2Desc: "สร้างเอกสารทางกฎหมายที่แม่นยำได้ทันทีด้วย AI ลดงานที่ซ้ำซ้อนและ <br />มั่นใจได้ว่าสอดคล้องกับมาตรฐานกฎหมายล่าสุด",
      feature1Title: "AI ช่วยค้นหากฎหมายและคดี",
      feature1Desc: "ค้นหาคดีฎีกา มาตรากฎหมาย และแนวคำพิพากษาที่เกี่ยวข้องได้ในพริบตา <br />AI ที่ช่วยเพิ่มความแม่นยำและความรวดเร็วในการค้นคว้ากฎหมาย",

      whatKhornCanDoTitle: "KHORN AI ทำอะไรได้บ้าง",
      whatKhornCanDoFeatures: [
        { title: "การจัดการคดีอัจฉริยะ", desc: "เปลี่ยนข้อมูลทางกฎหมายให้เป็นองค์ความรู้ที่จัดการได้ง่ายโดยอัตโนมัติ",image: "/images/case-management.png" },
        { title: "การร่างเอกสารทางกฎหมายด้วย AI", desc: "ช่วยร่างคำร้องและคำฟ้องที่แม่นยำและเป็นระบบได้ทันที", image: "/images/legal-drafting.png" },
        { title: "การค้นหากฎหมายและคดีขั้นสูง", desc: "ค้นหากฎหมายและคำพิพากษาที่เกี่ยวข้องได้ในไม่กี่วินาที",image: "/images/legal-search.png" },
        { title: "การให้เหตุผลทางกฎหมาย", desc: "เข้าถึงฐานข้อมูลคำพิพากษาในอดีกเป็นข้อมูลสนับสนุนการให้เหตึผลทางกฎหมาย", image: "/images/legal-reasoning.png" },
        { title: "การสรุปข้อมูลทางกฎหมาย", desc: "เปรียบเทียบและสรุปคดีนับพันได้อย่างรวดเร็ว พร้อมไฮไลต์ข้อมูลสำคัญ", image: "/images/legal-summarization.png" },
        { title: "การประมวลผลเอกสาร", desc: "รองรับการประมวลผลจากหลายรูปแบบทั้ง ข้อความ เสียง เอกสาร หรือรูปภาพ", image: "/images/document-processing.png" }
      ],
      getStarted: "เริ่มต้นใช้งาน",
    joinWaitlistTitle: "เข้าร่วมรายชื่อรอใช้ KHORN AI ได้แล้ว",
    joinWaitlistDesc: "ลงทะเบียนเพื่อเป็นคนแรกที่ได้รับการเข้าถึง",
    joinWaitlistButton: "เข้าร่วมตอนนี้",
    joinWaitlist: "เข้าร่วม waitlist",
    languageToggle: "TH",

  },
    en: {
      navigation: {
        home: "Home",
        useCases: "Use Cases",
        blog: "Blog",
        data: "Data",
      },
      heroTitle: "Reduce document time, enhance legal team efficiency with KHORN AI",
      heroQuote: "“Use AI for repetitive tasks, because your legal team's time is worth more than searching for data.”",
      waitlistButton: "JOIN Waitlist",

      platformTitle: "Our Platform",
      platformQuote: "KHORN is an AI-powered platform designed for law firms and legal professionals, helping to reduce repetitive tasks and connect with public and private resources efficiently in a single system.",
      legalWorkspace: "Your new legal research<br />workspace.",
      aggregateInfoTitle: "Aggregate information",
      aggregateInfo: "Gain insights from hundreds of documents in minutes.",
      sourceReachTitle: "Source within reach",
      sourceReach: "Every analysis, review, and answer is backed by vast references.",
      endlessAccessTitle: "Endless accessibility",
      endlessAccess: "Access all public laws and recorded cases in Thailand.",
      benefitsTitle: "Benefits",
      benefitsQuote: "Enhancing legal operations",
      unlockedKnowledgeTitle: "Unlocked Knowledge",
      unlockedKnowledge: "Unlock knowledge from past work, policies, and templates.",
      improvedOutcomesTitle:"Improved Outcomes ",
      improvedOutcomes: "Dive deeper into your research and consider more factors.",
      increasedProductivityTitle:"Increased Productivity",
      increasedProductivity: "Breeze through tedious tasks and spend your time creating value for your clients.",
      header: "Are These Challenges Slowing Down Your Legal Practice?",
      problemsTitle: "Are these your Problems?",
      problem1Title: "Struggling to manage multiple clients and legal documents efficiently?",
      problem2Title: "Spending too much time drafting repetitive legal documents?",
      problem3Title: "Finding relevant case law and statutes is slow and difficult?",

      handleTitle: "How Today Legal Team works",
      handle1: "Manual case tracking leads to disorganization.",
      handle2: "Drafting documents from scratch wastes time.",
      handle3: "Legal research across multiple sources is inefficient.",

      solutionTitle: "KHORN SOLUTION",
      solution1Title: `Provides Centralized client & case management for easy tracking.`,
      solution2Title: "Provides AI-assisted legal drafting to reduce repetitive work.",
      solution3Title: "Provides Fast, AI-powered search for case law and statutes.",

      closingStatement: "KHORN AI simplifies the legal process, making case management, document drafting, and legal research faster and more efficient.",
      
      featureSectionTitle: "See KHORN AI in Action",
      featureSectionDesc: "Discover how KHORN AI simplifies your legal workflow.",
      featureTitle: "KHORN AI Features",
      feature3Title: "AI Case Management",
      feature3Desc: "Keep all client cases, legal documents, and notes in one organized system. Improve collaboration and efficiency with smart tracking and secure storage.",
      feature2Title: "AI Legal Drafting",
      feature2Desc: "Generate accurate legal documents instantly with AI. Reduce repetitive work and ensure compliance with up-to-date legal standards.",
      feature1Title: "AI Legal Search",
      feature1Desc: "Find relevant case law, statutes, and precedents in seconds. AI enhances legal research by providing fast, reliable, and accurate results.",

      whatKhornCanDoTitle: "What KHORN Can Do",
      whatKhornCanDoFeatures: [
        { title: "Smart Case Management", desc: "Automate and extract your legal document into manageable knowledge.", image: "/images/case-management.png" },
        { title: "AI-Powered Legal Drafting", desc: "Instantly generate precise and well-structured legal documents.", image: "/images/legal-drafting.png" },
        { title: "Advanced Legal Search", desc: "Find relevant case law and statutes in seconds.", image: "/images/legal-search.png" },
        { title: "Legal Reasoning", desc: "Utilize past cases and court rulings as supporting evidence for legal reasoning.", image: "/images/legal-reasoning.png" },
        { title: "Legal Summarization", desc: "Quickly compare and summarize thousands of cases with key highlights.", image: "/images/legal-summarization.png" },
        { title: "Document Processing", desc: "Process multi-modal input whether it is audio, text, docx, or pictures.", image: "/images/document-processing.png" }
      ],
      getStarted: "Get Started",
    joinWaitlistTitle: "Join the KHORN AI Waitlist",
    joinWaitlistDesc: "Sign up to be among the first to gain access.",
    joinWaitlistButton: "Join Now",
    joinWaitlist: "JOIN WAITLIST",
    languageToggle: "EN",
      
  },
  };

  const t = translations[language];
  const handleSubscribe = () => {
    window.location.href = "https://docs.google.com/forms/d/1w8UKAe3M-XhiGjvZh6Slk1-fh2muYFVJWxS0xXHVVtE"; // Replace with your actual link
  };

  const demoSteps = [
    { 
      title: t.feature1Title, 
      description: t.feature1Desc,
      video: "/videos/search-demo.mp4" 
    },
    { 
      title: t.feature2Title, 
      description: t.feature2Desc,
      video: "/videos/draft-demo.mp4" 
    },
    { 
      title: t.feature3Title, 
      description: t.feature3Desc,
      video: "/videos/file-demo.mp4"  
    }
  ];
  

  const [activeIndex, setActiveIndex] = useState(0);

  // Auto cycle through the demo videos
  useEffect(() => {
    let interval;
  
    if (videoDuration > 0) {
      interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % demoSteps.length);
      }, videoDuration);
    }
  
    return () => clearInterval(interval);
  }, [videoDuration]); // Depend on video duration to update interval
  
  const handleVideoMetadata = (event) => {
    const duration = event.target.duration * 1000; // Convert seconds to milliseconds
    if (duration > 0) {
      setVideoDuration(duration);
    }
  };


  return (

    
    <div className="container mx-auto px-4 font-sans">
      {/* Navigation Bar */}
<header className="flex items-center justify-between px-4 py-4 border rounded-xl mt-4 mx-4 md:mx-20 navbar">
  {/* Logo */}
  <Link to="/" className="text-2xl font-bold">K</Link>

  {/* Navigation Menu (Desktop) */}
  <nav className="hidden md:flex space-x-6 items-center">
    {/* Join Waitlist Button */}
    <button
      className="bg-white border border-black text-black font-bold px-4 py-2 rounded-md hover:bg-gray-800 hover:text-white text-sm"
      onClick={handleSubscribe}
    >
      {t.joinWaitlist}
    </button>

    {/* Language Toggle Button */}
    <button
      className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 text-sm"
      onClick={toggleLanguage}
    >
      {t.languageToggle}
    </button>
  </nav>

  {/* Mobile Navigation Menu */}
  <nav className="block md:hidden">
    <ul className="flex space-x-4 items-center">
      {/* Join Waitlist Button (Mobile) */}
      <li>
        <Link
          to="/subscription"
          className="bg-white border border-black text-black font-bold px-3 py-1 rounded-md hover:bg-gray-800 hover:text-white text-sm"
        >
          {t.joinWaitlist}
        </Link>
      </li>
      {/* Language Toggle Button (Mobile) */}
      <li>
        <button
          className="bg-black text-white px-3 py-1 rounded-md hover:bg-gray-800 text-sm"
          onClick={toggleLanguage}
        >
          {t.languageToggle}
        </button>
      </li>
      {/* Login Button */}
      <li>
        <a
          href="https://app.khorn-ai.com"
          className="bg-black text-white px-3 py-1 rounded-md hover:bg-gray-800 text-sm"
        >
          LOGIN
        </a>
      </li>
    </ul>
  </nav>
</header>



    <main>
    {/* Hero Section */}
    <section className="container mx-auto px-4 lg:px-20 py-12 text-center">
    <h1 className="font-sf text-3xl md:text-5xl font-bold mb-6" 
    dangerouslySetInnerHTML={{ __html: t.heroTitle }}
    ></h1>
  <p className="text-base md:text-xl mb-8"><em>{t.heroQuote}</em></p>
  <button
    className="bg-black text-white px-6 py-3 rounded-md text-lg"
    onClick={handleSubscribe} // Redirect on click
  >
    {t.waitlistButton}
  </button>

  {/* Video Container */}
  <div className="relative mt-12 rounded-lg overflow-hidden w-full max-w-5xl mx-auto">
    <video
      className="w-full h-auto aspect-video object-cover"
      autoPlay
      loop
      muted
      playsInline
      controls={false}
      onLoadedMetadata={handleVideoMetadata} // Capture video duration dynamically
    >
      <source src="/videos/promote_video(TH).mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</section>


        {/* Problems, Handle, Solutions Section */}
        <section className="container mx-auto px-4 lg:px-20 py-12">
          
      <h2 className="text-3xl md:text-5xl font-bold mb-12 text-center"
      dangerouslySetInnerHTML={{ __html: t.header }}
        >
      </h2>
      <p className="text-lg text-center text-gray-600 mb-12"
      dangerouslySetInnerHTML={{ __html: t.closingStatement }}
        >
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Problems */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4"
      dangerouslySetInnerHTML={{ __html: t.problemsTitle }}
        ></h3>
          <ul className="text-gray-600 space-y-3">
            <li className="flex items-center">
              <span className="text-red-500 mr-2">●</span>
              <span dangerouslySetInnerHTML={{ __html: t.problem1Title }}></span>
            </li>
            <li className="flex items-center">
              <span className="text-red-500 mr-2">●</span>
              <span dangerouslySetInnerHTML={{ __html: t.problem2Title }}></span>
            </li>
            <li className="flex items-center">
              <span className="text-red-500 mr-2">●</span> 
              <span dangerouslySetInnerHTML={{ __html: t.problem3Title }}></span>
            </li>
          </ul>
        </div>

        {/* Handle */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">{t.handleTitle}</h3>
          <ul className="text-gray-600 space-y-3">
            <li className="flex items-center">
              <span className="text-blue-500 mr-2">●</span> 
              <span dangerouslySetInnerHTML={{ __html: t.handle1 }}></span>
            </li>
            <li className="flex items-center">
            <span className="text-blue-500 mr-2">●</span> 
            <span dangerouslySetInnerHTML={{ __html: t.handle2 }}></span>
            </li>
            <li className="flex items-center">
              <span className="text-blue-500 mr-2">●</span> 
              <span dangerouslySetInnerHTML={{ __html: t.handle3 }}></span>
            </li>
          </ul>
        </div>

        {/* Solutions */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4">{t.solutionTitle}</h3>
          <ul className="text-gray-600 space-y-3">
            <li className="flex items-center">
              <span className="text-green-500 mr-2">●</span> 
              <span dangerouslySetInnerHTML={{ __html: t.solution1Title }}></span>
            </li>
            <li className="flex items-center">
              <span className="text-green-500 mr-2">●</span>
              <span dangerouslySetInnerHTML={{ __html: t.solution2Title }}></span>
            </li>
            <li className="flex items-center">
              <span className="text-green-500 mr-2">●</span> 
              <span dangerouslySetInnerHTML={{ __html: t.solution3Title }}></span>
            </li>
          </ul>
        </div>
      </div>
    </section>


    <section className="container mx-auto px-4 lg:px-20">
      {/* Section Header */}
      <h2 className="text-3xl md:text-5xl font-bold mb-8 text-center">
        {t.featureSectionTitle}
      </h2>
      <p className="text-lg text-center text-gray-600 mb-12">
        {t.featureSectionDesc}
      </p>

      {/* Fixed Height Wrapper to Prevent Shifting */}
      <div className="relative min-h-[320px] flex flex-col md:flex-row items-stretch gap-6 transition-all duration-500">
        {/* Bullet Points & Descriptions */}
        <div className="w-full md:w-1/2 flex flex-col justify-center h-auto">
          {demoSteps.map((step, index) => (
            <div key={index} className="mb-6">
              <div
                className={`flex items-center cursor-pointer text-lg transition-all duration-300 ${
                  activeIndex === index ? "text-blue-600 font-bold" : "text-gray-600"
                }`}
                onClick={() => setActiveIndex(index)}
              >
                <span className="text-lg mr-3">●</span>
                <span dangerouslySetInnerHTML={{ __html: step.title }}></span>

              </div>
              
              {/* Smooth Expand Effect for Description */}
              <div
                className={`transition-all duration-500 overflow-hidden ${
                  activeIndex === index ? "max-h-40 opacity-100 translate-y-0" : "max-h-0 opacity-0 translate-y-2"
                }`}
              >
              <p
                className="text-gray-500 mt-2 pl-6 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: step.description }}
              ></p>
              </div>
            </div>
          ))}
        </div>

    {/* Video Display */}
    <div className="w-full md:w-1/2 flex justify-center h-auto relative">
      {/* Video Wrapper for Smooth Transition */}
      <div className="relative w-full max-w-4xl">
        {demoSteps.map((step, index) => (
          <video
          key={activeIndex}
            className={`absolute top-0 left-0 w-full h-auto rounded-lg transition-opacity duration-700 ${
              activeIndex === index ? "opacity-100" : "opacity-0"
            }`}
            autoPlay
            loop
            muted
            playsInline
            style={{ pointerEvents: "none" }}
            onLoadedMetadata={handleVideoMetadata}
          >
            <source src={step.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ))}
      </div>
    </div>
  </div>
</section>

{/* Second Section - Will Not Move */}
<section className="container mx-auto px-4 lg:px-20 py-24">
  <h2 className="text-3xl md:text-5xl font-bold mb-12 text-center">
    {t.whatKhornCanDoTitle}
  </h2>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
  {t.whatKhornCanDoFeatures.map((feature, index) => (
    <div key={index} className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all relative">
      {/* Image Container */}
      <div className="w-full rounded-lg overflow-hidden">
        <img
          src={feature.image || "/images/building.jpg"}
          alt={feature.title}
          className="object-cover w-1/4 h-1/4 mx-auto rounded-lg"
          onError={(e) => (e.target.src = "/images/building.png")}
        />
      </div>

      {/* Content */}
      <h3 className="text-xl font-semibold mt-4">{feature.title}</h3>
      <p className="text-gray-600 mt-2">{feature.desc}</p>
    </div>
  ))}
</div>

</section>






      </main>

    {/* Call-to-Action Section */}
    <section className="py-20 text-center">
      <div className="container mx-auto px-4">
        <h3 className="text-lg text-gray-600 mb-4">{t.getStarted}</h3>
        <h2 className="text-5xl font-bold mb-6">{t.joinWaitlistTitle}</h2>
        <p className="text-xl mb-8">{t.joinWaitlistDesc}</p>
        {/* Button to trigger waitlist signup */}
        <button
          className="bg-black text-white px-6 py-3 rounded-md text-lg flex items-center justify-center mx-auto"
          onClick={handleSubscribe} // Function to handle waitlist sign-up
        >
          {t.joinWaitlistButton}
        </button>
      </div>
    </section>




    {/* Footer */}
    <footer className="bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <div className="mr-2 text-3xl font-bold">K</div>
            <span className="text-xl">KHORN</span>
          </div>
          <nav>
          <ul className="flex space-x-6">
            {/* Instagram */}
            <li>
              <a href="https://www.instagram.com/YOUR_PROFILE" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
                <Instagram size={24} />
              </a>
            </li>

            {/* Facebook */}
            <li>
              <a href="https://www.facebook.com/YOUR_PAGE" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
                <Facebook size={24} />
              </a>
            </li>

            {/* LinkedIn */}
            <li>
              <a href="https://www.linkedin.com/in/YOUR_PROFILE" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
                <Linkedin size={24} />
              </a>
            </li>
          </ul>
          </nav>
        </div>
        <div className="flex justify-between items-center text-sm text-gray-500">
          <p>&copy; 2024 Khorn AI Solution Co., Ltd. All rights reserved.</p>
        </div>
      </div>
    </footer>
    </div>
  );
};

export default LandingPage;