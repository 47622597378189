import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  
import LandingPage from './LandingPage';  // Your existing landing page


const App = () => {
  return (
    <Router>
      <div >
        <Routes>
          {/* Route for Landing Page */}
          <Route exact path="/" element={<LandingPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
